@if $password_strengthen {
  .new-account__fieldset {
    .password-field {
      &__info {
        #{$ldirection}: 100%;
      }
    }
  }
  .password-reset-page {
    .password-reset {
      .password-field__info-reset {
        top: 50%;
        transform: translateY(-50%);
        @include breakpoint($bp--small-down) {
          transform: translateY(0);
        }
      }
    }
  }
  .profile-password-update {
    &__rules {
      li {
        color: $color--gray--dark;
        @include breakpoint($bp--small-down) {
          display: block;
        }
      }
    }
    .form-submit {
      &:disabled,
      &.disabled {
        border: 1px solid $color--gray;
      }
    }
  }
  .password-field {
    &__info {
      @include breakpoint($bp--medium-up) {
        width: 160px;
      }
    }
  }
  #my-appts-registration-form {
    .registration-password-container {
      .password-field__info {
        #{$ldirection}: 82%;
        margin-#{$ldirection}: 5px;
        bottom: 31%;
      }
    }
  }
}
