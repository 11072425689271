$color-light-gray: #cccccc;
$background-button: #000000;
$fontcolor-white: #ffffff;
$errorcolor-text: #b84947;
$tier1color: #f7b8d3;
$tier2color: #ed2124;
$tier3color: #acacae;
$color--red--opacity-02: rgba(176, 0, 32, 0.02);

.terms_agree {
  div.legal_statement {
    border: 1px solid $color-light-gray;
    height: 120px;
    line-height: 18px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 5px;
    word-break: break-all;
  }
  div.radio_group {
    margin: 8px 0 10px 0;
  }
  .form-item.privacy {
    float: left;
    margin-bottom: 25px;
  }
  .form-item.third_party {
    float: left;
  }
  .form-item.optional_privacy {
    float: left;
    margin-bottom: 25px;
  }
  .form-item.optional_third_party {
    float: left;
  }
  .form-item.terms {
    float: left;
  }
  span.radio {
    float: left;
  }
}

#sms_request_pin,
#sms_verify_pin {
  background-color: $background-button;
  padding: 10px;
  color: $fontcolor-white;
  border-radius: 24px;
  height: 54px;
  margin: 10px 0;
  width: 100%;
}

.mobile__account {
  select.selectbox,
  input {
    width: 27%;
  }
}

.profile-page__content {
  .mobile_profile-page {
    &__head {
      display: none;
    }
  }
  .new-mobile_sms__item {
    margin-top: 15px;
  }
  .birthday_col {
    select {
      width: 30%;
      margin-right: 2px;
    }
    .form_birthday {
      width: 100%;
    }
  }
  .gender_col {
    .radio {
      float: left;
    }
  }
  .form_birthday {
    width: 100%;
  }
  .profile-info {
    &__header {
      font-size: 15px;
    }
    &__change_pwd_link {
      cursor: pointer;
      line-height: get-line-height(15px, 63.75px);
      text-decoration: underline;
    }
  }
}

.newsletter-info__fieldset {
  .radio_promotions {
    clear: both;
    .picker-radio {
      float: left;
    }
    .field_label {
      display: block;
      padding-top: 10px;
    }
  }
}

.margin_top_bottom {
  margin: 10px 0;
}

.other_reason {
  textarea {
    width: 25%;
    height: 20%;
  }
}

#address-lookup-container {
  .address-lookup-wrapper {
    .address-list {
      width: 100%;
      height: 170px;
      border: 1px solid $color-light-gray;
      overflow: scroll;
      padding-top: 10px;
    }
  }
  ul.address-list {
    li.address-item {
      margin: 0 0 10px 10px;
      cursor: pointer;
    }
  }
}

.default-shipping {
  input {
    float: left;
  }
  label {
    padding-left: 20px;
    display: block;
  }
}

.address-book__others {
  .address-item {
    margin-bottom: 20px;
  }
}

#registration_short,
.profile-page {
  .mobile__account {
    .select2-container {
      width: 28% !important;
    }
  }
}

.account-order-history {
  .product__detail {
    width: 24% !important;
    margin-#{$ldirection}: 9% !important;
  }
}

.profile-info__fieldset {
  .form_birthday {
    .select2-container {
      width: 30% !important;
    }
  }
}

.order-return__content {
  .float-right {
    margin: 20px 0;
  }
}

.mobile-info {
  .mobile_number {
    label {
      display: block;
    }
  }
}

.site-container {
  .sidebar-profile-pic {
    .barcode-placeholder {
      margin-top: 20px;
      #barcode {
        width: 155px;
        height: 95px;
      }
    }
  }
  .account-order-history {
    .past-purchases {
      .past-purchases__item {
        .product-header {
          h6 {
            font-weight: bold;
            font-size: 10px;
          }
          .quantity {
            margin-#{$ldirection}: 28%;
          }
          .rating {
            margin-#{$ldirection}: 42%;
            width: 16%;
          }
          .price {
            margin-#{$rdirection}: 38%;
            width: auto;
          }
        }
        .product--teaser {
          .product__qty {
            margin-#{$ldirection}: 28%;
          }
          .product__rate {
            margin-#{$ldirection}: 42%;
            width: 15.11111%;
          }
          .product__price {
            margin-#{$ldirection}: 56%;
            width: 22.11111%;
            font-size: 1rem !important;
          }
          .product__add {
            width: 23%;
            &-to-bag {
              width: 100%;
              padding: 5px 0;
              font-size: 10px;
              line-height: 24px;
              height: 34px;
            }
          }
        }
      }
    }
  }
  .past-purchases {
    .grid--mpp {
      .product--teaser {
        .product__footer {
          .product-item__out-of-stock,
          .product-item__inactive {
            float: right;
            bottom: 10px;
            right: 34%;
          }
          .product-item__inactive {
            margin-right: 25%;
          }
        }
      }
    }
  }
}

.order-details-page {
  .order-info {
    &__item {
      .order_cancel {
        a {
          pointer-events: auto;
          border-bottom: 1px solid #dbdbdb;
        }
      }
    }
  }
}

#registration_short {
  .mobile {
    &__account {
      #sms_request_msg {
        display: inline-block;
      }
    }
  }
}

.account-page {
  .orders-list {
    .orders-list {
      &__table {
        .order-detail-item-link {
          white-space: nowrap;
          margin-right: 5px;
          display: inline-block;
          line-height: 14px;
        }
      }
    }
  }
}

#address-lookup-container {
  padding: 10px;
  fieldset {
    &.address {
      margin-bottom: 20px;
      padding-bottom: 10px;
      border-bottom: 1px solid #000;
    }
  }
  .address-search {
    h4 {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
  h3,
  h4 {
    font-size: 12px;
    line-height: 18px;
  }
  .address-info {
    margin-bottom: 12px;
  }
  #rna_keyword {
    width: 65%;
    margin-right: 8px;
    margin-bottom: 10px;
  }
  .town-submit-btn {
    vertical-align: middle;
    width: 30%;
  }
  .address-hint-container {
    h4 {
      font-weight: bold;
    }
  }
  .hint-table {
    width: 100%;
    margin: 20px 0px;
    th {
      width: 33.33%;
      padding: 10px 0 10px 15px;
      background: #f7f7f7;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      font-size: 12px;
      font-weight: bold;
    }
    td {
      padding: 10px 0 10px 12px;
      border-bottom: 1px solid #ddd;
      vertical-align: middle;
      font-size: 10px;
    }
  }
  #address-results-container {
    table {
      &.rna-list {
        margin: 15px 0;
        th {
          background-color: #f7f7f7;
          padding: 10px 10px 10px 15px;
          vertical-align: middle;
          font-weight: bold;
          text-align: center;
          border-top: 1px solid #ccc;
          border-bottom: 1px solid #ccc;
          font-size: 10px;
          &.sno {
            width: 50px;
          }
        }
        td {
          &.sno {
            width: 50px;
          }
        }
        th,
        td {
          &.full-address {
            width: 350px;
          }
        }
        th,
        td {
          &.postal {
            width: 95px;
          }
        }
        td {
          padding: 10px 10px 10px 15px;
          border-bottom: 1px solid #ccc;
          vertical-align: middle;
          font-size: 10px;
          .roadAddrPart1,
          .roadAddrPart2 {
            display: none;
          }
          .address1 {
            display: block;
            padding: 5px 0;
            cursor: pointer;
          }
          .address3 {
            cursor: pointer;
          }
        }
      }
    }
    tbody {
      max-height: 170px;
      overflow-y: auto;
      overflow-x: hidden;
      display: inline-block;
      width: 100%;
    }
    .search-results-header {
      display: none;
      font-weight: bold;
    }
  }
  #address-third-elements {
    margin-top: 10px;
    h4 {
      font-size: 14px;
      font-weight: bold;
      padding-bottom: 8px;
    }
    .address-details {
      background-color: #f7f7f7;
      padding: 10px;
      margin: 0 5px 15px;
      .street,
      .detail-address {
        margin: 10px;
      }
      label {
        width: 20%;
        display: block;
        float: left;
        font-weight: bold;
      }
      #addr2_input_node {
        width: 75%;
      }
      .zip {
        display: none;
      }
      .building {
        margin: 0 auto;
        width: 57%;
        .address-search-h {
          margin: 5px 0;
        }
      }
    }
    .address-submit-button {
      width: 50%;
      margin: 0 auto;
    }
  }
  #rna-loading,
  #rna-page-list {
    text-align: center;
  }
  .error-display {
    background-color: #f7f7f7;
    text-align: center;
    margin: 15px 0;
    .error_messages,
    .error-msg {
      color: #000;
      display: none;
      padding: 20px;
    }
  }
  #rna-page-list {
    .pagelist {
      cursor: pointer;
    }
  }
}

#colorbox {
  #cboxWrapper {
    #address_form_container {
      #address-lookup-container {
        .address-search {
          width: 100%;
        }
      }
    }
  }
}

.gift_popup {
  #cboxLoadedContent {
    background: black;
    color: #fff;
  }
  .icon--remove:before {
    color: #fff;
  }
}

div.loyalty_gift {
  color: #fff;
  text-decoration: none;
  text-align: center;
  .popup_gift_content {
    font-size: 18px;
  }
  .remind_me_later {
    font-size: 20px;
    margin-top: 87px;
  }
  .picker-checkbox.picker .picker-flag:before {
    margin-bottom: 10px;
    color: #fff;
  }
  .picker {
    .picker-label span.label-content {
      color: #fff;
    }
    .picker-handle:hover {
      border-color: #fff;
    }
  }
}

.mac-select__marketing-page {
  &__banner-logo {
    display: none;
  }
  &__title {
    width: auto;
    font-size: 24px;
  }
  &__benefits__section-title {
    h3 {
      font-size: 2.8rem;
    }
  }
  &__romance {
    &-header {
      text-align: $ldirection;
      font-size: 24px;
    }
    &-text {
      text-align: $ldirection;
    }
  }
  .loyalty__content-bottom__section-container {
    &-header {
      margin-top: 15%;
      font-size: 24px;
      text-align: $ldirection;
      .js-shop-header {
        line-height: 0;
        text-align: $ldirection;
      }
      .shop {
        &__sub-header {
          font-size: 11px;
          line-height: 1;
          &-text {
            margin-top: 5px;
            margin-bottom: 0;
            line-height: 1;
          }
        }
      }
    }
  }
}

.optional_privacy {
  .group_label {
    label {
      display: inline;
    }
  }
}

.optional_third_party {
  .group_label {
    label {
      display: inline;
    }
  }
}

.account-loyalty,
#loyalty__content,
.macselect-benefits,
.loyalty-select__bottom {
  .mac-select {
    &__color {
      &-tier1 {
        color: $tier1color;
      }
      &-tier2 {
        color: $tier2color;
      }
      &-tier3 {
        color: $tier3color;
      }
    }
    &__swatch {
      &-tier1 {
        background-color: $tier1color;
        background-image: none;
      }
      &-tier2 {
        background-color: $tier2color;
        background-image: none;
      }
      &-tier3 {
        background-color: $tier3color;
        background-image: none;
      }
    }
  }
}

.macselect-benefits {
  text-align: center;
  position: relative;
  &__joinnow {
    img {
      margin: 0 auto;
    }
    > a {
      position: absolute;
      left: 21.6%;
      top: 79%;
      width: 57.2%;
      height: 5.12%;
      z-index: 2;
      @include unset-underline-links($extend: false);
    }
  }
  &__disclaimer {
    border-top: $border;
    padding: 12px;
    margin: 6px 0;
  }
}

.progress_bar {
  &_left--header {
    font-size: 24px;
  }
  .progress_bar_right {
    margin: 0 10%;
  }
  .progress_bar_text {
    #{$ldirection}: 37%;
  }
  .points__expiration {
    left: 28%;
  }
  .progress_bar_container {
    width: 230px;
    height: 230px;
    margin-#{$rdirection}: 12px;
    canvas {
      width: 100%;
      height: 100%;
    }
  }
}

#loyalty__content {
  .loyalty__panel {
    &__offers {
      &__sku {
        &__header {
          &__title {
            font-size: 20px;
            line-height: 1.5;
            font-weight: bold;
            span {
              font-size: 17px;
              font-weight: normal;
            }
          }
          &__subtitle {
            width: auto;
          }
        }
        &__list {
          &-container {
            .tabbed-rewards-block__tabs {
              padding: 0;
              white-space: nowrap;
            }
            .tabbed-rewards-block__content-item {
              .grid--mpp__items .grid--mpp__item {
                .loyalty__panel__offers__sku__product__image-container {
                  height: auto;
                  .loyalty__panel__offers__sku__product__image {
                    height: auto;
                  }
                }
              }
            }
          }
        }
        &__product {
          &__header {
            font-size: 12px;
          }
        }
      }
      &--image {
        height: 400px;
        overflow: hidden;
      }
    }
    .loyalty__panel__transactions {
      &__title {
        font-size: 20px;
        font-weight: bold;
      }
      &__instruction,
      &__level__description {
        font-size: 12px;
        font-weight: normal;
      }
      &__list__column-activity__transactions {
        display: block;
        &-link {
          border: none;
        }
      }
      &__list__column-activity__transaction-link {
        border: none;
      }
    }
  }
}

.loyalty__content-bottom {
  &__section-container {
    margin: 10% 7%;
    display: none;
    .section-right {
      text-align: $ldirection;
      .section-title {
        font-weight: 700;
        font-size: 14px;
      }
      .section-container-leveltwo {
        height: 180px;
        .section-items {
          .section-listitems {
            height: 100px;
            &:nth-child(1) {
              padding-top: 23px;
            }
            &:nth-child(2) {
              padding-top: 30px;
            }
          }
        }
      }
      .section-container-levelthree {
        height: 420px;
        .section-items {
          .section-listitems {
            height: 103px;
            &:nth-child(1),
            &:nth-child(2) {
              padding-top: 24px;
            }
            &:nth-child(3) {
              height: 111px;
            }
          }
        }
      }
      .section-container-levelfour {
        height: 200px;
        .section-items {
          .section-listitems {
            height: 100px;
            &:nth-child(1),
            &:nth-child(2) {
              padding-top: 36px;
            }
          }
        }
      }
    }
    .section-left {
      .section-container-leveltwo {
        height: 181px;
        .arrow {
          border-top: 1px solid $fontcolor-white;
        }
        .section-title {
          transform: rotate(-90deg) translate(-76px, -14px);
          font-size: 11px;
          text-orientation: sideways-right;
          writing-mode: horizontal-tb;
          width: 116%;
          font-weight: 700;
        }
        .section-status {
          transform: rotate(-90deg) translate(-60px, 12px);
        }
      }
      .section-container-levelthree {
        height: 420px;
        .section-title {
          transform: rotate(-90deg) translate(-186px, -33px);
          text-orientation: sideways-right;
          writing-mode: horizontal-tb;
          width: 157%;
          font-weight: 700;
        }
        .section-status {
          transform: rotate(-90deg) translate(-169px, 10px);
          margin-left: -30px;
          position: absolute;
          margin-top: 4px;
        }
      }
      .section-container-levelfour {
        height: 199px;
        .section-title {
          transform: rotate(-90deg) translate(-73px, -31px);
          text-orientation: sideways-right;
          writing-mode: horizontal-tb;
          width: 137%;
          font-weight: 700;
        }
        .section-status {
          transform: rotate(-90deg) translate(-52px, 6px);
          margin-left: -30px;
          position: absolute;
          margin-top: 8px;
        }
      }
    }
    #terms-container {
      margin: 10px 0 0;
      width: 100%;
      text-align: $ldirection;
    }
  }
}

.site-footer--sticky.is-sticky {
  z-index: 1000;
}

#loyalty__page__index-bottom {
  .mac-select__marketing-page {
    &__romance {
      display: block;
    }
  }
}

.social-login {
  &__container {
    .opt-in {
      &__container {
        text-align: $ldirection;
        .group_label {
          label {
            display: inline;
          }
        }
        .radio {
          &.field {
            margin-#{$ldirection}: 36px;
            .picker {
              display: inline-block;
            }
          }
        }
        .naver_error {
          a {
            color: $errorcolor-text;
          }
        }
      }
    }
    .picker-radio {
      &.picker {
        &.checked {
          .picker-handle {
            .picker-flag {
              margin: 2px;
            }
          }
        }
      }
    }
  }
  &__button {
    margin-top: 25px;
    img {
      width: 90%;
      border-radius: 24px;
    }
  }
  .error_messages {
    display: none;
    background: $color--red--opacity-02;
    border-radius: 4px;
    border: 1px solid $color-dark-red;
    padding: 10px;
    width: auto;
    margin: 10px 0;
  }
}

#signin {
  .site-container {
    .sign-in-page {
      .picker-checkbox {
        .naver_error {
          a {
            color: $errorcolor-text;
          }
        }
      }
    }
    .birth_container {
      .birth_title {
        font-size: 15px;
        padding-bottom: 10px;
      }
      .birthday_col {
        label {
          padding-bottom: 5px;
        }
        .form_birthday {
          .field {
            border-color: $color-light-gray;
            width: 32%;
          }
          .selectbox {
            margin-#{$ldirection}: 10px;
            margin-#{$rdirection}: 0;
            width: 32%;
          }
        }
      }
      .radio {
        display: inline-flex;
        .picker {
          .picker-handle {
            @include swap_direction(margin, 0 5px 0 3px);
          }
        }
      }
    }
  }
}
