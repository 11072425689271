.page-wrapper-checkout {
  .opc__footer {
    @include swap_direction(padding, 1em 0 1em 0);
    text-align: center;
  }
  .mobile-breadcrumb {
    .checkout-progress-bar__list-item {
      width: 25%;
    }
  }
  .checkout {
    &__index-content {
      .checkout__sidebar {
        display: block;
      }
    }
    &__sidebar {
      padding-bottom: 0;
    }
    &__content {
      .checkout__panel-title {
        display: block;
      }
    }
    .cart-item {
      &__qty {
        @include swap_direction(margin, 3% 0 0 40%);
      }
      &__total {
        display: inline;
        width: 35%;
      }
      &__product-name {
        padding-right: 35px;
      }
    }
  }
  .checkout.viewcart {
    .checkout__sidebar {
      padding-bottom: 0;
    }
  }
  .cart-item__total {
    display: block;
  }
  #confirmation-page {
    .checkout__sidebar {
      margin: 0;
    }
  }
  footer.site-footer {
    display: block;
    .site-footer--top {
      display: none;
    }
    .site-footer--bottom {
      @include swap_direction(padding, 1em 0 10em 0);
    }
  }
}

.address__content,
.page-wrapper-checkout {
  .field-container--grid {
    .postal_code_container {
      width: 48%;
      float: $ldirection;
    }
    .lookup_container {
      width: 48%;
      float: $rdirection;
    }
  }
  .address1_container {
    width: 48%;
    display: inline-block;
  }
  .address2_container {
    width: 48%;
    display: inline-block;
    float: right;
  }
  .phone_container {
    .phone_part_area_code {
      width: 30%;
      display: inline-block;
    }
    .phone1,
    .phone2 {
      width: 70%;
      float: #{$rdirection};
    }
    .phone {
      height: 60px;
    }
    .phone_part {
      width: 45%;
      display: inline-block;
      padding-#{$ldirection}: 15px;
    }
    .phone1_1,
    .phone2_1 {
      @include swap_direction(margin, 0 7px 0 7px);
    }
  }
}

.checkout,
.profile-page,
.address__content {
  .adpl {
    .giftmsg_message_container,
    .birthday_col,
    .phone_container,
    .mobile_number {
      label {
        display: block;
      }
    }
    label {
      display: none;
    }
    input.error,
    select.error {
      border-color: $color--red;
    }
    .picker-radio,
    .picker-checkbox {
      label {
        display: block;
      }
    }
    .form-item,
    .required-message {
      margin-bottom: 15px;
    }
    select {
      height: auto;
      border: 1px solid $color--border;
      line-height: 58px;
    }
    .PHONE1_AREA_CODE,
    .PHONE2_AREA_CODE {
      @include swap_direction(margin, 0);
    }
    .phone_container {
      @include swap_direction(margin, 0);
      label {
        margin-bottom: 5px;
      }
    }
    .giftmsg_message_container {
      textarea {
        display: inline-block;
        background: none;
      }
      label {
        display: block;
        z-index: 999;
      }
    }
  }
  #review-address-info .trans_detail_item.offer_code {
    padding: 0;
    #offer-code-panel {
      padding: 1em;
    }
  }
}

.mobile__account {
  div.mobile_number_part {
    width: 30%;
    display: inline-block;
    select.selectbox,
    selectbox,
    input {
      width: 100%;
    }
  }
  span.mobile_number_space {
    @include swap_direction(padding, 0 2px 0 2px);
  }
}

.sign-in-page {
  .mobile_number {
    label {
      display: block;
      margin-bottom: 5px;
    }
  }
  .mobile_number_part {
    select {
      height: auto;
      border: 1px solid $color--border;
    }
  }
  .error_messages {
    @include swap_direction(margin, 0 0 20px 0);
  }
}

.profile-info {
  .profile-info__required {
    @include swap_direction(margin, 0 0 20px 0);
    display: block;
  }
  .error_messages {
    @include swap_direction(margin, 0 0 20px 0);
  }
}

.address-form {
  .address_field_heading.full_name {
    display: none;
  }
  .address_field_heading.address {
    @include swap_direction(margin, 0 0 10px 0);
  }
  .error_messages {
    @include swap_direction(margin, 0 0 20px 0);
  }
}
