@font-face {
  font-family: seoulnamsan-cb;
  src: url('#{$netstorage-font-path}' + 'cjk/Seoulnamsan/Seoulnamsan1.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: nanumbarungothic-regular;
  src: url('#{$netstorage-font-path}' + 'cjk/NanumBarun/NanumBarunGothic.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: nanumbarungothic-bold;
  src: url('#{$netstorage-font-path}' + 'cjk/NanumBarun/NanumBarunGothic-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@if $fonts_update == false {
  .product_rgn_name_below_subline {
    font-family: 'tstar_mono_roundregular', 'nanumbarungothic-regular', Helvetica, Arial, Sans-Serif !important;
  }
}

.form--newsletter__subtitle {
  font-weight: bold;
}

.form--newsletter {
  .form--newsletter__form-items {
    .btn {
      font-weight: bold;
    }
  }
}

.text-block--quote {
  .text-block__body {
    font-family: 'nanumbarungothic-regular', 'tstar_mono_roundregular', Helvetica, Arial, Sans-Serif !important;
    line-height: 1.2;
  }
  .text-block__name {
    font-family: 'seoulnamsan-cb', 'ano-bold_regular', Helvetica, Arial, Sans-Serif !important;
  }
}

.block--footer-menu {
  h6 {
    font-weight: bold;
  }
}

.product__name,
.product__add-to-bag,
.product__link-to-spp,
.product__link-to-spp,
.product__link-custom {
  font-weight: bold;
}

@if $fonts_update == false {
  .product--teaser {
    .product__footer,
    .product__header {
      .product__price {
        font-family: $ano-bold_regular-font !important;
      }
      .product__link-to-spp {
        font-family: $ano-bold_regular-font;
      }
    }
    .product__shade-column {
      .shade-picker__trigger {
        font-weight: bold;
      }
    }
  }
}

.page--spp__product {
  .product__footer {
    .product__product-details-shade-name {
      font-weight: bold;
    }
    @if $fonts_update == false {
      .product__size {
        font-family: 'tstar_mono_roundregular', 'nanumbarungothic-regular', Helvetica, Arial, Sans-Serif !important;
      }
      .product__price_status {
        font-family: $ano-bold_regular-font !important;
      }
      .product__description--compact {
        .expando-block__title {
          font-family: 'ano-bold_regular', 'seoulnamsan-cb', Helvetica, Arial, Sans-Serif !important;
        }
      }
    }
  }
}

.culture-landing__column {
  .culture-landing__item {
    .culture-landing__teaser-category {
      font-family: $ano-bold_regular-font !important;
    }
    .culture-landing__teaser-description {
      font-family: $tstar-font !important;
    }
    .culture-landing__tracks__meta-info {
      font-family: $tstar-font !important;
    }
  }
}

.slidein-clickaway {
  .shop-link {
    font-family: $tstar-font !important;
  }
}

@if $fonts_update == false {
  .account-page__header {
    .account-page__title {
      font-family: $tstar-font !important;
      font-weight: bold;
    }
    .orders__header {
      font-family: 'seoulnamsan-cb', $ano-bold_regular-font !important;
    }
    .past-purchases__link {
      font-family: $tstar-font !important;
      font-weight: bold;
    }
  }
  .narrowed_body_copy {
    h2 {
      font-family: $ano-bold_regular-font !important;
    }
  }
  .site-header {
    &__container {
      .site-header__tools {
        .site-my-mac__link {
          font-family: 'tstar_mono_roundregular', 'nanumbarungothic-regular', Helvetica, Arial, Sans-Serif !important;
        }
        .site-bag {
          .cart-product-price {
            font-family: $ano-bold_regular-font !important;
          }
          .site-bag__contents {
            &-subtotal__value {
              font-family: 'ano-bold_regular', 'seoulnamsan-cb', Helvetica, Arial, Sans-Serif !important;
            }
          }
        }
      }
      .block-image-hover__content {
        font-family: $tstar-font;
      }
    }
  }
  .site-container {
    .custom-grid__item-inner {
      .top_five_reasons_heading {
        span {
          font-family: 'tstar_mono_roundregular', 'nanumbarungothic-regular', Helvetica, Arial, Sans-Serif !important;
        }
      }
    }
    .checkout {
      &__content {
        .cart-item__price,
        .cart-item__total {
          font-family: $ano-bold_regular-font !important;
        }
      }
      .order-summary-panel {
        .value {
          font-family: $ano-bold_regular-font !important;
        }
      }
    }
    .artistry--artist-recap-tall h3,
    .artist-tile__header {
      font-family: 'tstar_mono_roundregular', 'nanumbarungothic-regular', Helvetica, Arial, Sans-Serif !important;
    }
  }

  .site-footer {
    .mobile-help__buttons {
      .grid--item {
        .btn {
          font-family: 'ano-bold_regular', 'seoulnamsan-cb', Helvetica, Arial, Sans-Serif !important;
        }
      }
    }
  }

  .collection-video-player__content {
    .collection-video-player__media {
      .teaser h5 {
        font-family: 'ano-bold_regular', 'seoulnamsan-cb', Helvetica, Arial, Sans-Serif !important;
      }
    }
  }

  .artistry--mosaic-tiles {
    .twitter-block__info {
      .twitter-block__user {
        @if $fonts_update == false {
          font-family: 'tstar_mono_roundregular', 'nanumbarungothic-regular', Helvetica, Arial, Sans-Serif !important;
        }
      }
    }
  }

  //Roboto-Mono font replaces the Tstar font
  .grid--mpp__item {
    .product--teaser {
      .product_header_details {
        .product_rgn_name_below_subline {
          font-family: $roboto-mono_regular;
        }
      }
    }
  }

  .page--spp-ultra-wide {
    .product--full {
      .product_rgn_name_below_subline {
        font-family: $roboto-mono_regular;
      }
    }
  }
}

.multi-use-tout {
  &__title {
    font-family: seoulnamsan-cb;
  }
}
