@mixin loyalty-checkout-offers {
  $checkout-accordion-background: #f0f0f0;
  .loyalty-offer-code-panel {
    margin: 0;
    padding: 1em;
    background: $checkout-accordion-background;
    header {
      padding: 0 15px;
      @include breakpoint($width-large - 1) {
        padding: 0;
      }
    }
    .offer-code-content__wallet {
      width: 100%;
      margin: 0 auto;
      padding: 20px 0;
      border: none;
      @if $fonts_update == false {
        font-family: $loyalty-tstar-font;
      }
      @include breakpoint($width-large - 1) {
        width: 95%;
      }
      .slick-slide {
        padding: 45px;
        @include breakpoint($width-large - 1) {
          padding: 10px;
        }
        .offer-code-wallet {
          border: none;
          &__code {
            &__image-container {
              border: 1px solid $color--black;
            }
            &__description {
              font-size: 12px;
              font-weight: bold;
              margin-top: 15px;
              @if $fonts_update == false {
                font-family: $loyalty-tstar-font;
              }
              &-title,
              &-points {
                font-size: 20px;
                margin-bottom: 5px;
                font-weight: bold;
                text-transform: uppercase;
                @if $fonts_update == false {
                  font-family: $loyalty-ano-bold-font;
                }
              }
              &-description {
                min-height: 60px;
              }
            }
          }
          &__button--apply {
            padding: 14px 42px;
            font-size: 15px;
            background: $color--black;
            color: $color--white;
            height: 45px;
            line-height: 1;
            text-decoration: none;
            border: none;
            @if $fonts_update == false {
              font-family: $loyalty-ano-bold-font;
            }
          }
        }
      }
      .slick {
        &-prev,
        &-next {
          position: absolute;
          top: 40%;
          @include breakpoint($width-large - 1) {
            top: 32%;
          }
          &:before {
            font-size: 20px;
          }
        }
        &-prev {
          &:before {
            display: block;
          }
        }
        &-list {
          width: 100%;
          padding: 0;
          @include breakpoint($width-large - 1) {
            width: 90%;
            margin: 0 auto;
            padding: 0;
          }
        }
      }
    }
    &__title,
    &__points {
      font-size: 25px;
      margin-bottom: 5px;
      text-transform: uppercase;
      text-align: center;
      display: inline-block;
      @if $fonts_update == false {
        font-family: $loyalty-ano-bold-font;
      }
    }
    &__title {
      &.checkout__panel-title {
        display: block;
        text-align: #{$ldirection};
        position: relative;
        i {
          position: absolute;
          #{$rdirection}: 0;
          top: 0;
        }
      }
    }
    &__points {
      font-size: 18px;
      margin-#{$ldirection}: 0;
      @include breakpoint($width-large - 1) {
        margin-#{$ldirection}: 10%;
      }
    }
    &__select {
      font-size: 15px;
      margin-bottom: 10px;
      text-align: center;
      @if $fonts_update == false {
        font-family: $loyalty-tstar-font;
      }
    }
  }
}
