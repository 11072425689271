@mixin loyalty-marketing-page {
  .mac-select__marketing-page {
    &__header-col1 {
      float: #{$ldirection};
      width: 100%;
      @include breakpoint($width-large - 1) {
        width: 65%;
      }
    }
    &__header-col2 {
      float: #{$ldirection};
      padding-#{$ldirection}: 0;
      @include breakpoint($width-large - 1) {
        float: #{$rdirection};
      }
      .mac-select__marketing-page__join__hyperlink,
      .mac-select__marketing-page__signin {
        padding: 0 55px;
      }
    }
    &__signin {
      display: block;
    }
    &__title {
      width: 55%;
      @if $fonts_update == false {
        font-family: $loyalty-ano-bold-font;
        @include breakpoint($width-large - 1) {
          width: 33%;
        }
      }
    }
    &__intro {
      margin-top: 10px;
      @if $fonts_update == false {
        font-family: $loyalty-tstar-font;
      }
    }
    &__romance {
      margin: 0 7%;
      @include breakpoint($width-large - 1) {
        @include swap_direction(margin, 2% 15% 5% 15%);
      }
      &-header {
        font-size: 36px;
        width: 65%;
        line-height: 1.2;
        @if $fonts_update == false {
          font-family: $loyalty-ano-bold-font;
        }
        @include breakpoint($width-large - 1) {
          width: 23%;
        }
      }
      &-text {
        @if $fonts_update == false {
          font-family: $loyalty-tstar-font;
        }
      }
    }
    .loyalty__content-bottom__section-container {
      position: relative;
      &-header {
        @include swap_direction(margin, 10% 10% 2% 10%);
        color: $color--white;
        width: 76%;
        line-height: 1;
        font-size: 45px;
        @if $fonts_update == false {
          font-family: $loyalty-ano-bold-font;
        }
        @include breakpoint($width-large - 1) {
          @include swap_direction(margin, 0 10% 2% 10%);
          font-size: 50px;
          @if $fonts_update == false {
            width: 20%;
          }
        }
      }
      &-shade {
        position: absolute;
        top: 50%;
        left: -60%;
        @include breakpoint($width-large - 1) {
          top: 57%;
          #{$ldirection}: -19%;
        }
      }
      &-ribbon {
        position: absolute;
        top: 0;
        #{$rdirection}: 0;
      }
      &-content {
        position: relative;
        z-index: 1000;
      }
    }
    &__lover {
      @include swap_direction(margin, 7% 2% 0 2%);
      text-align: #{$ldirection};
      @include breakpoint($width-large - 1) {
        text-align: center;
        margin: 0 15%;
      }
    }
    &__banner {
      &-logo {
        width: 55%;
        position: absolute;
        #{$ldirection}: 18%;
        top: 22%;
        @include breakpoint($width-large - 1) {
          #{$rdirection}: 9%;
          top: 15%;
          width: auto;
          #{$ldirection}: inherit;
        }
      }
    }
  }
  #loyalty__page__index-bottom {
    .mac-select__marketing-page {
      &__romance {
        display: none;
      }
    }
  }
}
