@mixin loyalty-checkout-birthday {
  //Birthday carousel
  .loyalty-birthday-panel {
    .product-brief-carousel {
      border: none;
      .slick {
        &-list {
          padding: 0;
        }
        &-prev {
          position: absolute;
          &:before {
            display: block;
            font-size: 15px;
          }
        }
      }
      .product__desc {
        font-family: $loyalty-tstar-font;
        margin-top: 10px;
        float: none;
        width: 100%;
        display: inline-block;
      }
      @if $fonts_update == false {
        .product__name,
        .button {
          font-family: $loyalty-ano-bold-font;
        }
      }
      .loyalty-birthday-panel__footer {
        text-align: center;
        .button {
          width: 55%;
          height: 50px;
          line-height: 3;
          margin: 0 auto;
        }
      }
      .active-offer,
      .offer-count {
        display: inline-block;
        margin: 0 3px;
      }
      .offer-index {
        text-align: center;
        font-size: 13px;
        width: 100%;
        margin-bottom: 10px;
      }
      .product--teaser {
        border: none;
      }
    }
  }
}
