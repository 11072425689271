@mixin loyalty-points {
  $tier1color: #f7b8d3;
  $tier2color: #ed2124;
  $tier3color: #acacae;
  #loyalty__content {
    .responsive-container {
      @include breakpoint($width-large - 1) {
        max-width: 100%;
      }
    }
  }
  #loyalty__panel__points {
    margin: 0;
    @include breakpoint($width-large - 1) {
      padding: 3% 22%;
    }
    .loyalty__panel__points {
      &__left {
        font-family: $loyalty-ano-bold-font;
        float: #{$ldirection};
        @include breakpoint($width-large - 1) {
          width: 30%;
        }
        width: 50%;
        .loyalty__panel__points {
          &__header {
            font-size: 20px;
            margin-bottom: 0;
          }
          &__count {
            font-size: 30px;
            font-weight: bold;
          }
          &__expiration {
            font-family: $loyalty-tstar-font;
            font-weight: bold;
          }
        }
        .loyalty__panel__about__section {
          font-family: $loyalty-tstar-font;
          font-weight: bold;
          display: inline-block;
          margin-top: 5%;
          .loyalty__panel__faq {
            display: block;
            width: 30%;
            margin-top: 10px;
          }
        }
      }
      &__right {
        float: #{$ldirection};
        width: 50%;
        @include breakpoint($width-large - 1) {
          width: 70%;
        }
        .mac-select__account-panel {
          max-width: inherit;
          padding: 0;
          margin: 0;
          &__logoheader {
            display: inline-block;
          }
          &__logo {
            float: #{$ldirection};
            width: 25%;
          }
          &__content-right {
            float: #{$ldirection};
            width: 70%;
            margin-top: 5%;
          }
        }
        .mac-select__progress {
          margin-top: 0;
          .mac-select__progress-label {
            display: none;
            @include breakpoint($width-large - 1) {
              display: inline-block;
            }
          }
        }
      }
    }
  }
  .account-loyalty {
    .mac-select__account-panel {
      &__header-left {
        float: #{$ldirection};
        width: 50%;
      }
      &__header-right {
        width: 50%;
        float: #{$ldirection};
        text-align: #{$rdirection};
      }
      &__content-title {
        margin-bottom: 25px;
      }
      &__content-spend {
        display: inline-block;
      }
      &__content-details {
        margin-top: 12%;
      }
    }
  }
  .loyalty__page__index-2col {
    display: none;
  }
  .site-header {
    .site-header__tools {
      .site-my-mac__contents {
        .site-my-mac__contents-footer__loyalty {
          @if $fonts_update == false {
            font-family: $loyalty-ano-bold-font;
          }
          .site-my-mac__contents-footer {
            &-title {
              display: inline-block;
            }
            &-copy {
              display: inline-block;
              width: 45%;
              text-align: #{$rdirection};
              margin-bottom: 5px;
            }
            &-link,
            &-link2 {
              position: inherit;
              bottom: inherit;
              @if $fonts_update == false {
                font-weight: bold;
                font-family: $loyalty-tstar-font;
              }
            }
            &-link2 {
              display: block;
              width: 33%;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
  .loyalty__header-banner {
    &--image {
      display: block;
      height: auto;
      margin: 0 auto;
      width: 100%;
    }
  }
  //progressbar
  .progress_bar {
    @include swap_direction(padding, 7% 15% 8% 15%);
    width: 100%;
    display: inline-block;
    @include breakpoint($width-large - 1) {
      @include swap_direction(padding, 2% 17% 3% 17%);
    }
    &__faq__content {
      clear: both;
      padding-top: 32px;
    }
    &_right {
      position: relative;
      float: #{$ldirection};
      margin-top: 10%;
      @include breakpoint($width-large - 1) {
        float: #{$rdirection};
        margin-top: inherit;
      }
    }
    &_left {
      float: #{$ldirection};
      width: 100%;
      @include breakpoint($width-large - 1) {
        width: 45%;
      }
      .mac_lover_logo {
        width: 200px;
      }
      &--header,
      &--links {
        margin-top: 7%;
        @if $fonts_update == true {
          font-size: 25px;
        } @else {
          font-family: $loyalty-ano-bold-font;
        }
      }
      @if $fonts_update == false {
        &--info {
          font-family: $loyalty-tstar-font;
        }
      }
      &--links {
        &-about,
        &-faq {
          font-size: 18px;
        }
        &-faq {
          display: block;
          width: 37px;
        }
      }
    }
    &_text {
      position: absolute;
      top: 20%;
      #{$ldirection}: 47px;
      text-align: center;
      .points_current {
        font-size: 40px;
        @if $fonts_update == false {
          font-family: $loyalty-ano-bold-font;
        }
      }
      @if $fonts_update == false {
        .points_header {
          font-family: $loyalty-ano-bold-font;
        }
      }
      .reward_status,
      .current_tier {
        margin: 0;
        font-weight: bold;
        word-spacing: -3px;
        @if $fonts_update == false {
          font-family: $loyalty-tstar-font;
        }
      }
      .current_tier {
        &_level1 {
          color: $tier1color;
        }
        &_level2 {
          color: $tier2color;
        }
        &_level3 {
          color: $tier3color;
        }
      }
    }
    .total_progress {
      position: absolute;
      bottom: 0;
      #{$ldirection}: 85px;
      text-align: center;
      @if $fonts_update == false {
        font-family: $loyalty-tstar-font;
      }
    }
    .points__expiration {
      text-align: center;
      position: absolute;
      #{$ldirection}: 43px;
      margin-top: 5px;
      @if $fonts_update == false {
        font-family: $loyalty-tstar-font;
      }
    }
  }
}
