.gift_options_container {
  #gift-wrap-img {
    width: 100px;
  }
}

.checkout__content {
  .cart-header__title--total {
    width: 11%;
  }
  .payment-method {
    .picker-radio {
      .picker-handle {
        margin: 0 15px 0 2px;
      }
    }
  }
  #recommended-products-panel {
    .recommended-product-items {
      .info {
        height: 15em;
      }
      .addtobag {
        input {
          font-size: 13px;
        }
      }
    }
  }
  .signin-to-see-cart a {
    color: $color--red;
    font-weight: bold;
  }
}

.review {
  #review-instructions {
    margin-top: 20px;
  }
  #checkout-buttons {
    &.naver_pay_btn {
      background: transparent center/250px 60px no-repeat url('/media/export/cms/checkout/naver_btn.png');
      color: transparent;
      min-width: 215px;
      height: 60px;
      border: none;
    }
  }
}

#review-address-info {
  .trans_detail_item {
    width: 100%;
    padding: 10px;
  }
  .change_link {
    float: right;
    margin-right: 10%;
  }
  address {
    clear: both;
  }
  .trans_header {
    border-bottom: 1px solid $color-light-gray;
    margin-bottom: 15px;
  }
}

p.escrow {
  display: none;
}

address {
  font-style: normal;
}

#confirmation-panel {
  .checkout-header__title {
    width: 100%;
    right: 0;
    text-align: center;
    padding-right: 0;
  }
}

.checkout_confirm {
  .account-nav__section {
    display: none;
  }
  .site-container {
    min-height: auto;
    min-width: auto;
  }
  .checkout__sidebar {
    margin-bottom: 20px;
  }
}

.newsletter-info {
  #email-promotions,
  #sms-promotions {
    .picker-radio {
      float: left;
      margin-right: 20px;
    }
  }
}

.continue-buttons {
  .disabled.btn {
    background-color: $color-light-gray;
    text-decoration: none;
    cursor: not-allowed;
  }
}

.site-container {
  .checkout {
    &-header {
      .continue-buttons {
        height: auto;
      }
    }
    .order-summary {
      &__content {
        .total {
          &.label {
            padding-top: 0;
            text-align: center;
            width: 100%;
            white-space: nowrap;
          }
          &.value {
            width: 100%;
          }
        }
      }
    }
  }
}

.gift_form_container {
  #gift_box--terms {
    color: #888;
  }
}

.viewcart-panel {
  .checkout {
    &__panel-content {
      .product {
        &__desc-info {
          .cart-item {
            &__price--mobile-only {
              display: none;
            }
          }
        }
      }
    }
  }
}

#cboxContent {
  #address-confirmation {
    text-align: center;
    padding: 30px;
    h2 {
      font-size: 16px;
      margin-bottom: 15px;
    }
    .change-address-content {
      font-size: 11px;
    }
    .address-confirm-buttons {
      margin-top: 20px;
      .address-confirm,
      .address-cancel {
        width: 40%;
        display: inline-block;
        margin-left: 10px;
        .btn {
          width: 100%;
        }
      }
    }
  }
}

.checkout__sidebar {
  #samples-panel {
    .product:hover {
      background-color: $color--gray--white;
      color: $color--black;
    }
  }
}

#bottom-viewcart-buttons {
  .sticky-checkout-button {
    .review-panel__loyalty-points__description {
      font-size: 13px;
      @if $fonts_update == false {
        font-family: $ano-bold_regular-font;
      }
    }
  }
}
