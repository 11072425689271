.picker {
  .picker-label {
    &.error {
      color: $color--error;
    }
  }
}

.privacy,
.terms,
.third_party {
  .group_label {
    label {
      display: inline;
    }
  }
}

.site-container {
  .product__shipping--mobile {
    margin-bottom: 15px;
  }
  .product__edd--mobile {
    display: none;
  }
  .product__share-bar {
    .product__social-links {
      width: 4em;
    }
  }
  #search-wrapper {
    .results-header {
      border-top: none;
    }
  }
  .product--teaser {
    .product_rgn_name_below_subline {
      margin-top: 5px;
    }
  }
  .order-details-page {
    .order-info__item {
      a {
        pointer-events: none;
        color: inherit !important;
        text-decoration: none;
        border-bottom: none;
      }
    }
  }
  .content {
    .mac-select__homepage-tout {
      &__ctas {
        bottom: 0;
      }
    }
  }
}

#cboxLoadedContent {
  padding-right: 25px;
}

.site-header {
  .site-header__tools {
    .block-template-basic-responsive-v1 {
      .site-email-signup {
        display: none;
      }
    }
  }
}

.section-privacy-policy,
.section-terms-conditions,
.section-counterfeit-education {
  .narrowed_body_copy {
    p {
      letter-spacing: 0.03em;
    }
  }
}

.loyalty {
  &__panel {
    &__offers {
      &__sku {
        &__list-container {
          display: none;
        }
      }
    }
  }
}

#colorbox {
  &.disconnect-overlay-launch {
    height: 200px !important;
  }
}
