@mixin loyalty-offers-sku {
  .loyalty__panel__offers__sku {
    text-align: center;
    width: 100%;
    border-bottom: 1px solid $color--gray--light;
    @include breakpoint($width-large - 1) {
      border-top: 1px solid $color--gray--light;
    }
    &__header {
      &-container {
        color: $color--white;
        position: absolute;
        margin: 70% 7%;
        z-index: 100;
        text-align: #{$ldirection};
        @include breakpoint($width-large - 1) {
          margin: 13% 7%;
        }
      }
      &__button-container {
        a {
          &.button {
            background: $color--white;
            color: $color--black;
          }
        }
      }
      &__title {
        margin: 10px 0;
        line-height: 1;
        @if $fonts_update == false {
          font-family: $loyalty-ano-bold-font;
        }
      }
      &__subtitle {
        width: 63%;
        margin-bottom: 35px;
        @if $fonts_update == false {
          font-family: $loyalty-tstar-font;
        }
        @include breakpoint($width-large - 1) {
          font-size: 15px;
        }
      }
      &__greet {
        text-transform: uppercase;
        letter-spacing: 5px;
        @if $fonts_update == false {
          font-family: $loyalty-tstar-font;
        }
        @include breakpoint($width-large - 1) {
          font-size: 15px;
        }
      }
    }
    &__title {
      margin: 0 0 20px;
      font-size: 30px;
      font-family: $loyalty-ano-bold-font;
      @include breakpoint($width-large - 1) {
        margin: 40px 0 20px;
      }
    }
    .button {
      padding: 13px 35px;
      background: $color--black;
      color: $color--white;
      @if $fonts_update == false {
        font-family: $loyalty-tstar-font;
        font-weight: bold;
      }
      @include breakpoint($width-large - 1) {
        font-size: 15px;
      }
      &--disabled {
        background: $color--gray--lightest;
        pointer-events: none;
      }
    }
    &__loader {
      display: none;
      &__button {
        &.not_meet {
          pointer-events: none;
          background: $color--gray--lightest;
        }
      }
    }
    &__list {
      &-container {
        @include breakpoint($width-large - 1) {
          margin-bottom: 10px;
        }
        .tabbed-rewards-block {
          &__content {
            &-item {
              height: 0;
              opacity: 0;
              overflow: hidden;
              transition: opacity 1s ease-in-out;
              width: 100%;
              &.current {
                height: auto;
                overflow: visible;
                opacity: 1;
              }
              .carousel-controls {
                .slick-prev {
                  &:before {
                    display: block;
                    font-size: 15px;
                  }
                }
              }
              .grid--mpp__items {
                .slick {
                  &-list {
                    width: 90%;
                    margin: 0 auto;
                    padding: 0 20px;
                    @include breakpoint($width-large - 1) {
                      padding: 0;
                      width: 95%;
                    }
                  }
                  &-track {
                    margin: 0 auto;
                  }
                }
                .grid--mpp__item {
                  border: none;
                  padding: 0;
                  .loyalty__panel__offers__sku {
                    &__product__image-container {
                      height: 320px;
                      border: 1px solid $color--black;
                      margin-bottom: 20px;
                      img {
                        height: 315px;
                      }
                    }
                    &__product__data__wrapper {
                      text-align: #{$ldirection};
                      @include breakpoint($width-large - 1) {
                        min-height: 135px;
                      }
                    }
                    &__cta__container {
                      text-align: #{$ldirection};
                    }
                  }
                }
              }
            }
          }
          &__tabs {
            margin-bottom: 20px;
            padding: 0 15px;
            width: 100%;
            @include breakpoint($width-large - 1) {
              padding: 0 80px;
              margin-bottom: 35px;
            }
          }
          &__tab {
            @include breakpoint($width-large - 1) {
              font-size: 20px;
            }
            padding: 10px 8px 10px;
            border-bottom: 1px solid $color--border;
            color: $color--border;
            cursor: pointer;
            letter-spacing: 0;
            font-size: 15px;
            line-height: 1;
            text-decoration: none;
            text-transform: uppercase;
            width: 15%;
            margin-bottom: 30px;
            text-align: center;
            @if $fonts_update == false {
              font-family: $loyalty-ano-bold-font;
            }
            &.current {
              border-bottom: 2px solid $color--black;
              color: $color--black;
            }
          }
          .grid--mpp__carousel {
            animation-duration: 1.5s;
          }
          .product-brief-carousel {
            border-top: none;
          }
        }
        .quickshop {
          &__container {
            @include breakpoint($width-large - 1) {
              width: auto;
              margin-#{$ldirection}: 0;
            }
            .product {
              &-gallery {
                @include breakpoint($width-large - 1) {
                  border-#{$rdirection}: none;
                  padding-#{$rdirection}: 10px;
                }
                &__photo {
                  &--large {
                    display: none;
                    &.active {
                      display: block;
                    }
                  }
                }
                &__thumbs {
                  display: none;
                }
              }
              &__divider {
                display: none;
              }
              &-replenishment {
                &-select {
                  display: none;
                }
              }
              &__actions {
                padding: 0;
                .product {
                  &__button {
                    background: $color--black;
                    margin-top: 20px;
                  }
                }
              }
              &__details {
                @include breakpoint($width-large - 1) {
                  @include swap_direction(padding, 40px 20px 20px 5px);
                }
                .product {
                  &__header {
                    display: block;
                    border: none;
                  }
                  &__title {
                    @include breakpoint($width-large - 1) {
                      display: inline-block;
                    }
                    font-size: 10px;
                  }
                  &__rating {
                    display: none;
                  }
                  &__actions {
                    text-align: #{$ldirection};
                    .product {
                      &__full-details-link-wrapper,
                      &__favorite {
                        display: none;
                      }
                    }
                  }
                }
                .mobile-price,
                .rating_fav {
                  display: none;
                }
                .quickshop-container__offer__needed__pts {
                  float: #{$rdirection};
                }
              }
              &__sku {
                &-categories {
                  &-nav {
                    display: none;
                    &-bottom {
                      border: 1px solid $color--gray--light;
                    }
                  }
                }
                &-select {
                  &-label {
                    display: none;
                  }
                }
              }
              &__promo {
                display: none;
              }
              &_quickshop {
                &__header {
                  font-size: 14px;
                }
              }
            }
            &__offer {
              &-needed {
                &-pts {
                  float: #{$rdirection};
                }
              }
            }
          }
          &-wrapper {
            @include breakpoint($width-large - 1) {
              margin-top: 20px;
            }
            &.active {
              &--1 {
                &:before {
                  #{$ldirection}: 50%;
                  @include breakpoint($width-large - 1) {
                    #{$ldirection}: 7%;
                  }
                }
              }
              &--2 {
                &:before {
                  #{$ldirection}: 50%;
                  @include breakpoint($width-large - 1) {
                    #{$ldirection}: 41%;
                  }
                }
              }
              &--3 {
                &:before {
                  #{$ldirection}: 50%;
                  @include breakpoint($width-large - 1) {
                    #{$ldirection}: 75.66%;
                  }
                }
              }
            }
          }
          .wrap {
            @include swap_direction(padding, 0);
          }
        }
        .product-brief-carousel {
          border: none;
        }
      }
    }
    &__subtitle {
      @include breakpoint($width-large - 1) {
        font-size: 15px;
      }
      position: inherit;
      font-size: 15px;
      line-height: 1.25;
      text-align: center;
      padding-bottom: 10px;
      &__points {
        margin: 20px 0 10px;
        font-size: 20px;
        font-family: $loyalty-ano-bold-font;
      }
      &__selecttext {
        text-transform: initial;
        font-family: $loyalty-tstar-font;
        letter-spacing: 0.05em;
        margin-bottom: 10px;
      }
    }
    &__select {
      text-align: center;
      line-height: 1.25;
    }
    &__benefits {
      text-align: center;
      margin-top: 5px;
      &__link {
        text-decoration: underline;
        font-size: 18px;
        text-transform: capitalize;
      }
    }
    &__product {
      padding: 22px;
      text-align: center;
      &__image {
        &-container {
          @include breakpoint($width-large - 1) {
            height: 250px;
          }
        }
      }
      &__header {
        font-size: 20px;
        margin-bottom: 10px;
        min-height: 35px;
        text-transform: uppercase;
        @if $fonts_update == false {
          font-family: $loyalty-ano-bold-font;
        }
      }
      &__sub-header {
        font-size: 15px;
        margin-bottom: 10px;
        min-height: 10px;
        font-family: $loyalty-tstar-font;
      }
      &__offer-code {
        padding: 10px 0;
        text-transform: uppercase;
        font-size: 18px;
        @if $fonts_update == false {
          font-family: $loyalty-ano-bold-font;
        }
      }
    }
    .carousel-controls {
      position: relative;
    }
    .slick-next,
    .slick-prev {
      @include breakpoint($width-large - 1) {
        position: absolute;
        height: auto;
      }
      top: 200px;
      height: 0;
      &:before {
        @include breakpoint($width-large - 1) {
          border-radius: 31px;
          width: 28px;
          height: 28px;
          font-size: 20px;
        }
        border-radius: 20px;
        padding-top: 7px;
        font-size: 23px;
        position: relative;
        width: 40px;
        height: 40px;
      }
    }
    .slick-prev {
      @include breakpoint($width-large - 1) {
        #{$ldirection}: -12px;
        margin-#{$ldirection}: 0;
      }
      margin-#{$ldirection}: 9px;
      &:before {
        @include breakpoint($width-large - 1) {
          @include swap_direction(padding, 4px 5px 0 0);
        }
        @include swap_direction(padding, 7px 4px 0 0);
      }
    }
    .slick-next {
      @include breakpoint($width-large - 1) {
        margin-#{$rdirection}: 0;
        #{$rdirection}: -14px;
      }
      margin-#{$rdirection}: 3px;
      #{$rdirection}: 0;
      &:before {
        #{$rdirection}: 0;
        @include breakpoint($width-large - 1) {
          @include swap_direction(padding, 4px 0 0 5px);
          #{$rdirection}: auto;
        }
      }
    }
    &__more {
      &__link {
        color: $color--black;
      }
    }
  }
  .loyalty__offer__terms {
    padding: 20px 0;
    font-size: 13px;
    &__link {
      margin-top: 15px;
      .terms-link {
        text-decoration: underline;
      }
    }
  }

  // loyalty transaction table
  #loyalty__panel__transactions {
    padding: 20px;
    @include breakpoint($width-large - 1) {
      padding: 30px 50px;
    }
    .points_content {
      td {
        padding-#{$ldirection}: 0;
      }
    }
  }
  .loyalty__page__index-mobile {
    display: none;
  }
}
