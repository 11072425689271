@mixin loyalty-events_and_promos {
  .mac-lover__events__promos {
    @include swap_direction(margin, 3% 0 0 7%);
    &--header {
      line-height: 1;
      width: 85%;
      font-size: 50px;
      @if $fonts_update == false {
        font-family: $loyalty-ano-bold-font;
      }
      @include breakpoint($width-large - 1) {
        @if $fonts_update == true {
          width: 40%;
        } @else {
          font-size: 60px;
          width: 30%;
        }
      }
    }
    &--description {
      width: 90%;
      @if $fonts_update == false {
        font-family: $loyalty-tstar-font;
      }
      @include breakpoint($width-large - 1) {
        width: 60%;
      }
    }
    &--image {
      width: 90%;
      height: auto;
      margin-bottom: 5%;
    }
  }
}
