@mixin loyalty-offers {
  #loyalty__panel__offers {
    padding: 10% 0;
    @include breakpoint($width-large - 1) {
      padding: 5% 0;
    }
    .loyalty__panel__offers__offer-description {
      @include breakpoint($width-large - 1) {
        width: 100%;
      }
    }
    .loyalty__panel__offers__offer {
      padding: 22px;
      @include breakpoint($width-large - 1) {
        padding: 10px;
      }
    }
  }
  .loyalty__panel__offers {
    @include swap_direction(padding, 40px 0 24px);
    font-family: $loyalty-tstar-font;
    border-bottom: 1px solid $color--gray--light;
    &__list {
      &-container {
        .slick-dots {
          li {
            margin: 0;
            height: 17px;
            width: 17px;
            bottom: 35px;
            button {
              &:before {
                font-size: 36px;
              }
            }
          }
        }
        .offers_2_carousel {
          @include swap_direction(margin, 5px auto 0 auto);
          width: 84%;
          height: 33px;
          border: none;
          .offers_2_carousels {
            margin: 0 7px;
          }
          .shade-item {
            @include swap_direction(margin, 4px 0 4px 12px);
            width: 25px;
            height: 25px;
            border-radius: 50%;
            display: block;
            &.active {
              border: 1px solid $color--black;
            }
          }
        }
        .slick-prev {
          position: absolute;
          @include breakpoint($width-large - 1) {
            #{$ldirection}: 7px;
            border-top-#{$rdirection}-radius: 100px;
            border-bottom-#{$rdirection}-radius: 100px;
          }
          #{$ldirection}: 3.5%;
          &:before {
            @include breakpoint($width-large - 1) {
              border-bottom-#{$ldirection}-radius: 0;
              border-top-#{$ldirection}-radius: 0;
              padding-#{$ldirection}: 0;
              #{$rdirection}: 2px;
            }
            border-bottom-#{$ldirection}-radius: 20px;
            border-top-#{$ldirection}-radius: 20px;
            #{$rdirection}: 2px;
            padding-#{$ldirection}: 6px;
          }
        }
        .slick-next {
          #{$rdirection}: 2%;
          @include breakpoint($width-large - 1) {
            #{$ldirection}: 97%;
            border-top-#{$ldirection}-radius: 100px;
            border-bottom-#{$ldirection}-radius: 100px;
          }
          &:before {
            @include breakpoint($width-large - 1) {
              border-bottom-#{$rdirection}-radius: 0;
              border-top-#{$rdirection}-radius: 0;
              padding-#{$rdirection}: 0;
              #{$ldirection}: 7px;
            }
            #{$rdirection}: auto;
            border-bottom-#{$rdirection}-radius: 20px;
            border-top-#{$rdirection}-radius: 20px;
            padding-#{$rdirection}: 6px;
          }
        }
        .slick-next,
        .slick-prev {
          @include breakpoint($width-large - 1) {
            opacity: 0.8;
            width: 18px;
            height: 30px;
          }
          top: 34%;
          &:before {
            @include breakpoint($width-large - 1) {
              font-size: 18px;
              position: relative;
              background: none;
            }
            font-size: 23px;
            padding-top: 6px;
            width: 20px;
            height: 36px;
          }
        }
      }
    }
    &__title {
      line-height: 1;
      text-align: center;
      margin-bottom: 10px;
      letter-spacing: normal;
      font-family: $loyalty-ano-bold-font;
    }
    &__subtitle {
      line-height: 1.5;
      text-align: center;
      font-size: 14px;
      margin-bottom: 5px;
      font-weight: bolder;
    }
    &__error {
      text-align: center;
    }
    &__redeem {
      text-transform: uppercase;
      font-size: 14px;
      text-align: center;
      margin-bottom: 3%;
      font-weight: bolder;
    }
    &__select {
      text-align: center;
      line-height: 1.5;
    }
    &__benefits {
      text-align: center;
      margin-top: 5px;
      &__link {
        text-decoration: underline;
        font-size: 16px;
        text-transform: capitalize;
      }
    }
    &__loader {
      display: none;
    }
    &__offer {
      padding: 10px;
      text-align: center;
      &-image-container {
        height: 320px;
      }
      &-header {
        font-size: 18px;
        text-transform: uppercase;
        line-height: 1;
        margin-bottom: 10px;
        letter-spacing: normal;
        padding-top: 16px;
        min-height: 22px;
      }
      &-description {
        font-size: 13px;
        min-height: 55px;
        margin-bottom: 15px;
      }
      &-valid {
        margin-bottom: 10px;
        text-transform: uppercase;
        font-size: 13px;
      }
      &-header,
      &-description,
      .loyalty__panel__offers__cta {
        text-align: #{$ldirection};
      }
      @if $fonts_update == false {
        &-header,
        .loyalty__panel__offers__cta {
          font-family: $loyalty-ano-bold-font;
        }
      }
    }
    &__button {
      &-container {
        margin-top: 3%;
        .button {
          &--disabled {
            background: $color--gray--lightest;
            pointer-events: none;
          }
        }
      }
    }
    &__button,
    &__selected,
    &__remove,
    &__redeemed {
      padding: 14px 42px;
      font-size: 15px;
      background: $color--black;
      color: $color--white !important;
    }
    &__limits {
      @include breakpoint($width-large - 1) {
        border-top: 1px solid $color--gray--light;
        padding-top: 45px;
        margin-top: 30px;
      }
      margin-top: 18px;
      &__title {
        @include breakpoint($width-large - 1) {
          padding-#{$ldirection}: 0;
        }
        line-height: 0;
        padding-#{$ldirection}: 20px;
      }
      &__content {
        &-container {
          @include breakpoint($width-large - 1) {
            padding: 0;
          }
          padding: 0 20px;
        }
      }
      &__terms-conditions {
        @include breakpoint($width-large - 1) {
          padding: 0;
        }
        padding: 0 20px;
        &__link {
          color: $color--gray--dark;
        }
      }
    }
    .product-brief-carousel {
      border: none;
      .slick {
        &-prev {
          position: absolute;
          &:before {
            display: block;
            font-size: 15px;
          }
        }
        &-list {
          margin: 0 auto;
          width: 95%;
          padding: 0 20px;
          @include breakpoint($width-large - 1) {
            padding: 0;
          }
        }
        &-track {
          margin: 0 auto;
        }
      }
      .offers_2_carousel {
        height: 75px;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        white-space: normal;
        @include breakpoint($width-large - 1) {
          height: 125px;
        }
        .product-brief-shades__shade-item {
          width: 25%;
          text-align: center;
          .shade-item {
            @include swap_direction(margin, 0 0 5px 0);
            width: 100%;
            height: 50px;
            border-radius: 0;
            display: block;
            @include breakpoint($width-large - 1) {
              height: 90px;
            }
          }
          &__title {
            margin-bottom: 10px;
          }
        }
      }
    }
    &__offer-image {
      height: 295px;
      display: inline-block;
      &-container {
        border: 1px solid $color--black;
        @include breakpoint($width-large - 1) {
          display: block;
          width: 100%;
        }
        img {
          margin: 0 auto;
        }
      }
    }
    &--image {
      position: relative;
    }
    &--content {
      position: absolute;
      z-index: 100;
      color: $color--white;
      float: #{$rdirection};
      width: 65%;
      margin: 70% 7%;
      @include breakpoint($width-large - 1) {
        @include swap_direction(margin, 7% 12% 0 0);
        #{$rdirection}: 0;
        width: 25%;
        #{$ldirection}: inherit;
      }
      .loyalty_panel_birthday {
        &_greet {
          letter-spacing: 6px;
          @include breakpoint($width-large - 1) {
            font-size: 15px;
          }
        }
        &_text {
          font-family: $loyalty-ano-bold-font;
          @include breakpoint($width-large - 1) {
            width: 65%;
          }
        }
        &_left {
          width: 100%;
          margin: 10px 0;
          display: inline-block;
          @include breakpoint($width-large - 1) {
            width: 88%;
            font-size: 15px;
          }
        }
      }
      &-btn {
        padding: 13px 35px;
        background: $color--white;
        color: $color--black;
        display: inline-block;
        margin-top: 10px;
        font-family: $loyalty-ano-bold-font;
        @include breakpoint($width-large - 1) {
          font-size: 15px;
        }
      }
    }
  }
}
