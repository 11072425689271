.product-foundation-vto {
  &__overlay {
    &-copy {
      padding: 0 20px;
    }
    &-title {
      line-height: get-line-height(18px, 25px);
    }
    &-holder {
      top: 9%;
    }
    &-check {
      .picker {
        .picker-handle,
        .picker-flag {
          border-color: $color--white;
          &:before {
            color: $color--white;
          }
        }
        &-handle {
          #{$ldirection}: 35%;
          position: relative;
          top: 1.4em;
        }
      }
    }
  }
  &__container {
    overflow: scroll;
  }
  &__guide {
    margin-bottom: 0;
  }
}
