@mixin loyalty-transaction-table {
  #loyalty__content {
    .loyalty__content-bottom__section {
      margin-top: 40px;
    }
    .loyalty__panel__history__table {
      .loyalty__panel__transactions {
        &__table {
          margin: auto;
          border: none;
          letter-spacing: 1px;
          border-top: 1px solid $color--gray--lightest;
          @if $fonts_update == false {
            font-family: $loyalty-tstar-font;
          }
          @include breakpoint($width-large - 1) {
            width: 81%;
            border-bottom: 1px solid $color--gray--lightest;
          }
          table,
          th,
          td,
          tr {
            border: none;
            color: $color--black;
            @include breakpoint($width-large - 1) {
              font-weight: bold;
            }
          }
          tr:first-child {
            @include breakpoint($bp--medium-down) {
              border-top: 1px solid $color--gray--lightest;
            }
          }
          td {
            padding-bottom: 15px;
            @include breakpoint($width-large - 1) {
              @include swap_direction(padding, 0 0 15px 0);
            }
          }
          th {
            display: none;
            @include breakpoint($width-large - 1) {
              display: table-cell;
              padding: 20px 0;
              font-weight: normal;
              @if $fonts_update == false {
                font-size: 18px;
                font-family: $loyalty-ano-bold-font;
              }
              .loyalty__panel__transactions__list {
                &__column-date {
                  padding-#{$ldirection}: 0;
                }
              }
            }
          }
          &__body {
            .points_content {
              @include breakpoint($bp--medium-down) {
                font-size: 16px;
                td {
                  padding-top: 0;
                }
              }
            }
            .set-border {
              @include breakpoint($bp--medium-down) {
                border-bottom: 1px solid $color--gray--lightest;
              }
            }
            .set-bold {
              @include breakpoint($bp--medium-down) {
                font-weight: bold;
                font-family: $loyalty-ano-bold-font;
              }
            }
            .loyalty__panel__transactions__list {
              &__column-minus,
              &__column-plus {
                @include breakpoint($bp--medium-down) {
                  float: #{$rdirection};
                  font-size: 30px;
                  font-weight: normal;
                }
              }
            }
            .loyalty__panel__transactions__list {
              @include breakpoint($width-large - 1) {
                &__column-date {
                  padding-#{$ldirection}: 0;
                }
              }
            }
          }
        }
        &__instruction {
          font-weight: bold;
          text-align: center;
          @if $fonts_update == false {
            font-family: $loyalty-tstar-font;
          }
        }
        &__level__description {
          text-align: center;
          margin-bottom: 28px;
          @if $fonts_update == false {
            font-family: $loyalty-tstar-font;
          }
        }
        &__title {
          text-align: center;
          text-transform: uppercase;
          font-size: 30px;
          @if $fonts_update == true {
            font-family: $ano-bold_regular-font;
          } @else {
            font-family: $loyalty-ano-bold-font;
          }
        }
        &__list__column {
          &-date {
            width: 28%;
            padding-#{$ldirection}: 0;
            @include breakpoint($width-large - 1) {
              width: 15%;
            }
          }
          &-activity {
            @include breakpoint($width-large - 1) {
              width: 50%;
            }
            &__transactions {
              display: inline;
            }
          }
          &-points {
            width: 10%;
          }
          &-total {
            width: 1%;
            padding-#{$rdirection}: 0;
          }
        }
        &__paginate {
          text-align: center;
          margin-top: 30px;
          .prev_disabled,
          .next_disabled {
            pointer-events: none;
            color: $color--gray--lightest;
          }

          .transactions-footer__view-more-link {
            text-transform: uppercase;
            font-size: 11px;
            margin-top: 15px;
            display: block;
          }
        }
      }
    }
  }
}
