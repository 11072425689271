@mixin loyalty-benefits-table {
  // Benefits table for PC

  .loyalty__content-bottom {
    .macselect-benefits {
      &__container {
        width: 90%;
        margin: 0 auto;
        @include breakpoint($width-large - 1) {
          @include swap_direction(padding, 0 0 0 20px);
          width: 100%;
          background: $color--white;
        }
        .macselect-benefits__section {
          &-fields {
            width: 40%;
            float: #{$ldirection};
            .macselect-benefits__section {
              &-title {
                height: 160px;
                padding-top: 20px;
                h1 {
                  margin: 0;
                }
              }
              &-copy {
                ul {
                  list-style: none;
                  li {
                    border-bottom: 1px solid $color--border;
                    padding: 10px 0;
                    margin: 0;
                    height: 56px;
                    align-items: center;
                    text-transform: uppercase;
                    @if $fonts_update == false {
                      font-family: $ano-bold_regular-font;
                    }
                    &:nth-child(3) {
                      padding-top: 21px;
                    }
                    &:last-child {
                      border: none;
                    }
                    p {
                      text-transform: initial;
                      margin: 0;
                    }
                  }
                }
              }
            }
          }
          &-levels {
            width: 60%;
            display: inline-block;
            &__header {
              height: 160px;
              @if $fonts_update == false {
                font-family: $ano-bold_regular-font;
              }
              &-title {
                margin: 0;
                @if $fonts_update == true {
                  font-size: 28px;
                } @else {
                  font-size: 30px;
                }
              }
              &-level {
                text-transform: uppercase;
                margin: 0;
                font-size: 12px;
              }
              &-status {
                margin: 0;
                font-size: 10px;
              }
            }
            .macselect-benefits__section {
              &-level1,
              &-level2,
              &-level3,
              &-level4 {
                width: 25%;
                border-left: 1px solid $color--border;
                float: #{$ldirection};
                .macselect-benefits__section {
                  &-levels__header {
                    color: $color--white;
                    padding: 10px;
                  }
                  &-copy {
                    li {
                      height: 56px;
                      border-bottom: 1px solid $color--border;
                      padding-top: 20px;
                      span {
                        border-radius: 10px;
                        width: 20px;
                        height: 20px;
                        margin: 0 auto;
                        display: block;
                      }
                      &:last-child {
                        border: none;
                      }
                    }
                  }
                }
              }
              &-level1 {
                .macselect-benefits__section {
                  &-levels__header {
                    background-color: $color--black;
                  }
                  &-copy {
                    li {
                      span {
                        background-color: $color--black;
                      }
                    }
                  }
                }
              }
              &-level2 {
                .macselect-benefits__section {
                  &-levels__header {
                    background-color: $color--pink-tier1;
                  }
                  &-copy {
                    li {
                      span {
                        background-color: $color--pink-tier1;
                      }
                    }
                  }
                }
              }
              &-level3 {
                .macselect-benefits__section {
                  &-levels__header {
                    background-color: $color--red-tier2;
                  }
                  &-copy {
                    li {
                      span {
                        background-color: $color--red-tier2;
                      }
                    }
                  }
                }
              }
              &-level4 {
                border-#{$rdirection}: 1px solid $color--border;
                .macselect-benefits__section {
                  &-levels__header {
                    background-color: $color--gray-tier3;
                  }
                  &-copy {
                    li {
                      span {
                        background-color: $color--gray-tier3;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &__header {
          background: $color--black;
          color: $color--white;
          padding-bottom: 20px;
          h1 {
            font-family: $loyalty-ano-bold-font;
            font-size: 45px;
            margin-bottom: 0;
          }
        }
        &__content {
          color: $color--white;
          display: flex;
          .levels {
            height: 250px;
          }
          .arrow {
            width: 0;
            height: 0;
          }
          &__left {
            flex: 0;
            .levels {
              border: 1px solid $color--white;
              border-bottom: 0;
              border-right: 0;
            }
            .macselect-benefits__container__content {
              &__level1 {
                height: 156px;
                background-color: $color--black;
              }
              &__level2 {
                height: 180px;
                border-top: 0;
                background-color: $color--pink-tier1;
                .arrow {
                  border-left: 35px solid $color--pink-tier1;
                  border-right: 35px solid $color--pink-tier1;
                  border-top: 20px solid $color--black;
                }
              }
              &__level3 {
                height: 180px;
                background-color: $color--red-tier2;
                border-top: 0;
                .arrow {
                  border-left: 35px solid $color--red-tier2;
                  border-right: 35px solid $color--red-tier2;
                  border-top: 20px solid $color--pink-tier1;
                }
              }
              &__level4 {
                height: 180px;
                border-top: 0;
                background-color: $color--gray-tier3;
                border-bottom: 1px solid $color--white;
                .arrow {
                  background: $color--red-tier2;
                  border-left: 35px solid $color--gray-tier3;
                  border-right: 35px solid $color--gray-tier3;
                  border-top: 20px solid $color--red-tier2;
                }
              }
            }
          }
          &__right {
            width: 100%;
            .macselect-benefits__container__content {
              &__level1 {
                height: 156px;
                li {
                  height: 52px;
                  border-bottom: 0;
                }
              }
              &__level2,
              &__level3,
              &__level4 {
                height: 180px;
                li {
                  height: 45px;
                  border-bottom: 0;
                }
              }
              &__level4 {
                background-color: $color--gray-tier3;
                li {
                  &:last-child {
                    border-bottom: 1px solid $color--white;
                  }
                }
              }
              &__level2 {
                background-color: $color--pink-tier1;
              }
              &__level3 {
                background-color: $color--red-tier2;
              }
            }
            ul {
              li {
                padding: 10px;
                border: 1px solid $color--white;
              }
            }
          }
          &-title {
            margin: 0;
            transform: rotate(-90deg) translate(-60px, -20px);
            @if $fonts_update == true {
              font-size: 14px;
              letter-spacing: unset;
            } @else {
              font-size: 10px;
              font-family: $ano-bold_regular-font;
            }
          }
          &-level {
            margin: 0;
            transform: rotate(-90deg) translate(-50px, -8px);
            font-size: 10px;
          }
          &-status {
            margin: 0;
            transform: rotate(-90deg) translate(-39px, 12px);
            font-size: 10px;
          }
        }
      }
      .macselect-benefits__section {
        &-button-container,
        &-terms-container {
          width: 80%;
          margin: 0 auto;
          text-align: center;
          color: $color--white;
        }
        &-button-container {
          .button {
            background: $color--white;
            color: $color--black;
            font-size: 15px;
            margin: 25px 0;
            display: inline-block;
            text-decoration: none;
            border: 0;
            @if $fonts_update == true {
              padding: 0 30px;
              line-height: 45px;
            } @else {
              font-family: $loyalty-ano-bold-font;
              padding: 12px 45px;
            }
            &--disabled {
              background: $color--gray--lightest;
              pointer-events: none;
            }
          }
        }
        &-terms-container {
          padding-bottom: 5px;
          &__faq {
            font-size: 15px;
            margin-top: 40px;
            @if $fonts_update == false {
              font-family: $loyalty-ano-bold-font;
            }
            a {
              color: $color--white;
              border: none;
              text-decoration: underline;
            }
          }
          &__terms-text {
            margin-bottom: 7px;
            @if $fonts_update == false {
              font-family: $loyalty-tstar-font;
            }
            a {
              color: $color--white;
              border: none;
            }
          }
        }
      }
    }
    &__section-container {
      background: $color--black;
      padding: 5% 0;
      &-content {
        margin: 10% 10%;
        @include breakpoint($width-large - 1) {
          margin: 0 10%;
        }
      }
    }
  }
}
