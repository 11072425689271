@mixin loyalty-ageconsent {
  .age-confirmation-popup {
    #cboxLoadedContent {
      background: $color--black;
      color: $color--white;
      padding: 20px 15px;
    }
    .ageconsent-container {
      border: 1px solid $color--white;
      font-size: 14px;
      margin-top: 50%;
      @include breakpoint($width-large - 1) {
        margin-top: inherit;
      }
      .logo-image {
        min-height: 55px;
        width: 28%;
        margin: 0 auto;
        margin-top: 5px;
      }
      .button-container {
        .form-item {
          margin: 0 auto;
          float: none;
          padding: 0;
          text-align: center;
          width: 38%;
        }
        .btn {
          background: $color--white;
          color: $color--black;
          font-family: inherit;
          line-height: 2;
          height: auto;
          padding: 0;
          width: 100%;
          font-size: 16px;
          margin-bottom: 20px;
          &:hover {
            color: $color--black;
            text-decoration: none;
          }
        }
      }
    }
    .ageconsent-form {
      text-align: center;
      min-height: 180px;
      .response-no {
        padding-top: 40px;
      }
      .response-yes {
        padding-top: 80px;
      }
      .required-message {
        text-align: center;
        margin-bottom: 0;
        .age-consent {
          margin-top: 20px;
          margin-bottom: 0;
        }
      }
      .ageconsent-text {
        text-align: center;
        display: inline-block;
        label {
          display: inline-block;
          margin-#{$rdirection}: 25px;
        }
        .field_label {
          display: none;
        }
        input[type="radio"] {
          width: 10px;
          height: 10px;
          appearance: none;
          margin-#{$rdirection}: 15px;
          outline-offset: 2px;
          outline: 1px solid $color--white;
          -moz-appearance: none;
          -webkit-appearance: none;
          &:checked {
            display: inline-block;
            background-color: $color--white;
          }
        }
        .ageconsent-option {
          text-align: #{$ldirection};
          margin-top: 15px;
        }
      }
    }
  }
  .loyalty-footer-text {
    display: block !important;
    background: $color--black;
    color: $color--white;
    text-align: center;
    line-height: 2;
    margin: 10px 0 5px;
    .footer-link {
      text-transform: none;
      color: $color--white;
    }
  }
}
