ul.error_messages {
  li {
    color: $errorcolor-text;
  }
}

.prod_inv_status-2 {
  .product__footer {
    .product__inventory-status {
      .temp-out-of-stock {
        display: block;
        background-color: $color--gray--light;
        text-align: center;
        line-height: 60px;
        height: 60px !important;
      }
    }
  }
}

.sign-in-page {
  .new-account {
    .account_promo {
      label {
        display: inline-block;
      }
      .radio_group {
        .picker-radio {
          display: inline-block;
        }
      }
    }
    .field-container--grid {
      .new-account__item {
        &.checkbox {
          margin-bottom: 5px;
        }
      }
      .pwd_msg {
        padding-bottom: 10px;
        font-size: 12px;
        color: red;
      }
    }
  }
}

.product {
  &__share-wrapper--mobile {
    .product {
      &__share-bar {
        .product {
          &__social-links {
            width: 100%;
            li {
              display: inline-block;
              float: none;
              margin: 0;
              padding: 10px;
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}

body {
  &.email_us {
    .site-container {
      min-height: 100%;
      padding-bottom: 25px;
    }
  }
}

.grid--mpp {
  .product {
    &__footer {
      .product {
        &__link-to-spp {
          #sku_count {
            margin-right: -4px;
          }
        }
      }
    }
  }
}

.page--spp {
  &__product {
    .product {
      &__footer {
        .view-all-shades {
          &__text {
            #sku_count {
              margin-right: -5px;
            }
          }
        }
      }
    }
  }
}

.mpp-custom {
  &.mpp-custom--custom-palette {
    .grid--mpp {
      &__item {
        .product--not-shoppable {
          .product {
            &__detail {
              .product {
                &__link-custom {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

.section-offer-details {
  .block-system-main {
    .customer-service {
      &__page {
        h2 {
          line-height: 52px;
        }
      }
    }
  }
}

.product--teaser {
  &.product--collection-dual {
    &.product--not-shoppable {
      + .carousel-controls {
        background: transparent;
      }
    }
  }
}

.contact-us-page {
  .form-item {
    &.mobile-phone {
      p {
        padding-top: 7px;
      }
    }
  }
}

.loyalty_privacy {
  .group_label {
    label {
      display: inline;
    }
  }
}

.product-full {
  &__review-snippet {
    .p-w-r {
      .pr-snippet-stars-reco-inline {
        .pr-snippet {
          .pr-snippet-read-and-write {
            .pr-snippet-review-count {
              &:before {
                content: ' 모든 상품평 읽기 ';
              }
            }
          }
        }
        &.pr-snippet-minimal {
          .pr-snippet-stars-container {
            &:before {
              content: '전체 평점: ';
            }
          }
        }
      }
    }
  }
}

.product-brief-carousel {
  .product-brief {
    &__product-name {
      float: none;
    }
  }
}

@if $cr21 == true {
  .cr21-refresh {
    .product-full--enhanced.product-full {
      .product-full__omni-wrapper {
        .bopis-spp-block {
          .bopis-spp-container {
            margin: 0;
          }
        }
      }
    }
  }
}

.footer-menu-li {
  display: inline-block;
  height: 15px;
  margin: 0;
}

.site-footer {
  &--bottom {
    .footer-span {
      .site-footer-span {
        display: inline-block;
        overflow: hidden;
        vertical-align: middle;
        a {
          color: $color--white;
          text-decoration: underline;
        }
      }
      .span-float-lf {
        float: left;
      }
      .span-float-rt {
        float: right;
      }
    }
  }
}

.account-loyalty {
  .mac-select__account-panel {
    &__content-details {
      clear: both;
    }
    &__content-processing {
      width: 300px;
      @include breakpoint($bp--medium-landscape) {
        width: 225px;
      }
    }
  }
}
