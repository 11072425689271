$color-pika-single-grey: #cccccc;

.appt-book {
  .confirm {
    .confirm-container {
      #booking-step3 {
        .sign-in-container {
          .sign-in-link-container {
            h4 {
              @include breakpoint(max-width $width-xsmall - 1) {
                padding-right: 5px;
                margin-top: 3px;
                line-height: 1;
                padding-#{$ldirection}: 0;
              }
            }
          }
        }
        .book-appt-container {
          &.no-last-name {
            .appt-book-first-name {
              width: 100%;
            }
            .appt-book-last-name {
              display: none;
            }
          }
          .book-appt-fieldset {
            .register-password-container {
              display: none;
            }
            .appt-book-mobile-prefix {
              border-right: 1px solid $color--gray--light !important;
            }
            .appt_mobile_phone_seperator {
              float: left;
              height: 100%;
              line-height: 5;
              width: 5%;
              text-align: center;
            }
            .appt-book-mobile {
              &.appt_mobile_part1,
              &.appt_mobile_part2 {
                width: 35%;
                float: left;
              }
            }
          }
          .ab-legal-container {
            @include flex-row;
            .terms-col {
              flex-grow: 1;
              flex-basis: 0;
              .legal_statement {
                width: 94%;
              }
              @include breakpoint(max-width $width-large - 2) {
                .accept-sms-notification-head,
                .accept-terms-head {
                  height: 2em;
                }
              }
            }
          }
          .terms-col {
            span {
              &.radio {
                @include breakpoint(max-width $width-xsmall - 1) {
                  display: block;
                  margin-bottom: 10px;
                }
              }
            }
            .radio_group {
              margin: 9px 0 26px 0;
              input {
                width: 13px;
                margin: 0;
                display: inline-block;
                height: auto;
              }
              label {
                margin-right: 10px;
              }
            }
          }
          .legal_statement {
            border: 1px solid $color--gray--light;
            margin: 16px 0;
            overflow-y: scroll;
            height: 150px;
            width: 100%;
            font-size: 14px;
          }
        }
      }
    }
  }
  .appt-book-page-header {
    .appt-book-page-header-content {
      h2 {
        margin-bottom: 10px;
      }
    }
  }
  .appointment-select {
    .appointments-container {
      .appointment-select {
        .appt-book-datepicker-overlay {
          .overlay-content-container {
            margin-left: -213px;
            .pika-single {
              border-top: 1px solid $color-pika-single-grey;
            }
          }
        }
      }
    }
  }
}

.device-mobile {
  .appt-book {
    .confirm {
      .confirm-container {
        #booking-step3 {
          .book-appt-container {
            .book-appt-fieldset {
              .appt_mobile_phone_seperator {
                line-height: 4;
              }
            }
          }
        }
      }
    }
  }
}

.elc-response-input-group-title {
  font-size: 1.375rem;
}